.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .view {
    flex: 1;
  }
}
