.questionary {
  padding: 0 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (orientation: landscape) and (max-device-width: 900px) {
    overflow-y: scroll;
  }

  @media screen and (max-width: 768px) {
    overflow-y: scroll;
  }

  &__msg {
    opacity: 0.8;
  }

  &__top {
    padding: 1rem 0;

    p {
      color: #ccc;
      font-size: 1.2rem;
      max-width: 1000px;
      width: 90%;
      margin: 0 auto;
      text-align: center;

      @media screen and (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }

  &__bottom {
    flex: 1;
    width: 100%;
    position: relative;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
