.navbar {
  width: 100%;
  height: 7vh;
  background-color: #1c2938;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #848484;

  @media screen and (max-width: 1400px) {
    height: 10vh;
  }

  // @media screen and (max-width: 1200px) {
  //   margin-bottom: 1rem;
  // }

  @media screen and (max-width: 900px) {
    padding: 1rem;
  }

  @media screen and (orientation: landscape) and (max-width: 900px) {
    height: 11vh;
  }

  @media screen and (max-width: 320px) {
    padding: 0.8rem 0.25rem;
  }

  &__title {
    font-size: 1.25rem;
    display: flex;
    align-items: center;

    h1 {
      letter-spacing: 2px;
    }

    &--all {
      display: flex;
      align-items: center;

      svg {
        max-width: 6rem;
      }

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      span {
        color: #8bd8bd;
      }

      @media screen and (max-width: 600px) {
        display: block;
      }
    }

    a {
      text-decoration: none;
      color: #fff;
    }

    &--mobile {
      width: 8rem;

      svg {
        width: 100%;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
  }

  &__menu {
    display: flex;
    align-items: center;
    list-style: none;

    @media screen and (max-width: 768px) {
      display: none;
    }

    a {
      font-size: 1.25rem;
      text-decoration: none;
      color: inherit;
      opacity: 0.5;
      transition: opacity 0.25s;

      & {
        margin-right: 1.5rem;

        @media screen and (max-width: 900px) {
          margin-right: 1rem;
        }
      }

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
        color: #fff;
      }
    }
  }

  &__btn {
    display: none;
    width: 30px;
    height: 30px;
    margin-left: 1.5rem;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    @media screen and (max-width: 320px) {
      margin-left: 0.75rem;
    }

    div {
      height: 2px;
      border-radius: 2px;
      background-color: #fff;
      width: 100%;
      transition: all 0.3s;
    }

    &.close {
      .bar-1 {
        transform: rotate(45deg) translate(3px, 9px);
      }

      .bar-2 {
        background-color: transparent;
      }

      .bar-3 {
        transform: rotate(-45deg) translate(2px, -8px);
      }
    }
  }
}
