@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

html {
  width: 100vw;
  height: 100vh !important;
  box-sizing: border-box;
  overflow: hidden;

  // &.translated-ltr {
  //   transform: translateY(-40px) !important;
  //   overflow: hidden !important;
  // }

  @media screen and (max-width: 1300px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }

  @media screen and (max-width: 900px) {
    font-size: 13px;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  height: 100vh;
  width: 100vw;
  font-family: 'Nunito Sans', sans-serif;
  color: #ddd;
  background: radial-gradient(75% 147% at 75% 61%, #2c3e50 0%, #1c2938 100%);
  overflow: hidden;

  // @media screen and (orientation: landscape) and (max-device-width: 900px) {
  //   overflow-y: scroll;
  // }

  // @media screen and (max-width: 768px) {
  //   overflow-y: scroll;
  // }
}

body > div {
  width: 100%;
  height: 100%;
}

body {
  top: 0 !important;
}

.goog-te-banner-frame {
  display: none !important;
}

::selection {
  background-color: #8bd8bd;
  color: #2c3e50;
}

.skiptranslate {
  width: 0;
  height: 0;

  iframe {
    width: 0;
    height: 0;
  }
}

#google_translate_element select {
  font-size: 0.95rem;
  outline: none;
  border-radius: 4px;
  background: #1c2938;
  color: #ddd;
  padding: 0.3rem 1.5rem;
  width: fit-content;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0.7rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar {
    width: 0.6rem;
  }

  &:hover::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba($color: #aaa, $alpha: 0.75);
    border-radius: 15px;

    &:hover {
      background-color: rgba($color: #8bd8bd, $alpha: 1);
    }
  }

  option {
    font-size: 0.9rem;
  }
}

#google_translate_element div {
  width: fit-content;
  height: 36px;
  overflow: hidden;
}

#google_translate_element span {
  display: none;
}

#goog-gt-tt {
  visibility: hidden !important;
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
