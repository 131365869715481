.home {
  display: flex;
  flex-direction: column;

  @media screen and (orientation: landscape) and (max-device-width: 900px) {
    overflow-y: scroll;
  }

  @media screen and (max-width: 768px) {
    overflow-y: scroll;
  }

  &__top {
    flex: 1;
    display: flex;

    @media screen and (orientation: landscape) and (max-device-width: 900px) {
      flex-direction: row;
    }

    @media screen and (orientation: portrait) and (max-width: 768px) {
      flex-direction: column;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    &--left {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 2.5rem;

      @media screen and (max-width: 1400px) {
        padding: 1.5rem;
      }

      @media screen and (max-width: 1200px) {
        padding: 1.7rem;
      }

      @media screen and (orientation: landscape) and (max-device-width: 800px) {
        flex: 4.5;
      }

      &--main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @media screen and (orientation: landscape) and (max-width: 1000px) {
          flex-direction: column;
          justify-content: center;
        }

        @media screen and (orientation: portrait) and (max-width: 768px) {
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
        }

        @media screen and (max-width: 600px) {
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
        }

        .patent {
          letter-spacing: 1px;
          font-family: 'Cinzel', serif;
          font-size: 1rem;
          margin-top: 1.5rem;
          border-radius: 4px;
          transition: all 0.3s;

          @media screen and (max-width: 480px) {
            margin-left: 1.5rem;
            margin-top: 0;
            flex: 1;
          }

          // @media screen and (max-width: 1200px) {
          //   position: absolute;
          //   top: 11vh;
          //   left: 50%;
          //   transform: translate(-50%, 0.3rem);
          //   margin-left: 0;
          //   width: fit-content;
          // }
        }
      }

      @media screen and (max-width: 900px) {
        padding: 0 0 0 0.5rem;
      }

      @media screen and (orientation: landscape) and (max-device-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0.7rem 0 0.7rem 1.2rem;
      }

      svg {
        @media screen and (orientation: landscape) and (max-width: 1000px) {
          width: 60px;
          height: 60px;
        }

        @media screen and (max-width: 900px) {
          width: 60px;
          height: 60px;
        }

        @media screen and (orientation: landscape) and (max-device-width: 900px) {
          display: none;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      h2 {
        font-size: 3.25rem;
        letter-spacing: 4px;
        margin: 2rem 0;

        @media screen and (max-width: 1400px) {
          font-size: 2.7rem;
        }

        @media screen and (max-width: 900px) {
          font-size: 2rem;
        }

        @media screen and (orientation: landscape) and (max-device-width: 900px) {
          font-size: 1.5rem;
          margin: 0.7rem 0;
        }

        @media screen and (max-width: 480px) {
          font-size: 1.7rem;
        }

        span {
          display: block;
        }
      }

      &--desc {
        //width: 85%;
        //margin: 0 15% 0 0;
        font-size: 1rem;
        color: #dddddd;
        margin-bottom: 1.5rem;

        // @media screen and (orientation: landscape) and (max-device-width: 1000px) {
        //   width: 100%;
        //   margin: 0;
        // }

        // @media screen and (orientation: portrait) and (max-width: 1000px) {
        //   width: 70%;
        //   margin: 0 auto;
        // }

        // @media screen and (orientation: portrait) and (max-width: 900px) {
        //   width: 80%;
        //   margin: 0 auto;
        // }

        // @media screen and (max-width: 768px) {
        //   width: 95%;
        //   margin: 0 auto;
        // }

        p {
          margin: 1.5rem 0 0.4rem 0;

          // @media screen and (max-width: 600px) {
          //   margin: 0.8rem 0 0.4rem 0;
          // }
        }
      }
    }

    &--right {
      flex: 7;
      display: flex;
      align-items: center;
      overflow-x: hidden;

      @media screen and (max-width: 1000px) {
        margin-left: 1.5rem;
      }

      @media screen and (orientation: landscape) and (max-device-width: 900px) {
        overflow-x: scroll;
      }

      @media screen and (orientation: landscape) and (max-device-width: 800px) {
        flex: 5.5;
      }

      @media screen and (max-width: 480px) {
        margin-left: 1rem;
        overflow-x: scroll;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem 2rem 3rem;

    @media screen and (max-width: 1000px) {
      padding: 1rem 2rem;
    }

    @media screen and (orientation: landscape) and (max-device-width: 900px) {
      display: none;
    }

    @media screen and (max-width: 480px) {
      display: none;
    }

    &--squares {
      display: flex;
      align-items: center;

      .square {
        cursor: pointer;
        width: 0.5rem;
        height: 0.5rem;
        background: #999;
        border-radius: 2px;

        &:not(:last-child) {
          margin-right: 0.75rem;
        }

        &.active {
          width: 0.8rem;
          height: 0.8rem;
          background: #8bd8bd;
        }
      }
    }

    &--arrows {
      display: flex;
      align-items: center;

      .rotate180 {
        transform: rotate(180deg);

        &:hover {
          transform: rotate(180deg) scale(1.2);
        }

        &:active {
          transform: rotate(180deg) scale(1);
        }
      }

      .line {
        height: 3px;
        width: 70px;
        background-color: #888;
        border-radius: 1px;
        margin: 0 0.5rem;
      }

      svg {
        cursor: pointer;
        transition: all 0.25s;

        &:hover {
          transform: scale(1.2);

          path {
            fill: #8bd8bd;
          }
        }

        &:active {
          transform: scale(1);
        }
      }
    }
  }
}
