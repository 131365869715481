.form {
  width: 50%;
  min-width: 750px;
  min-height: 400px;
  color: #fff;
  border: 1px solid #888;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 1rem;
  background: linear-gradient(
    to right bottom,
    rgba(44, 81, 107, 0.4),
    rgba(44, 81, 107, 0.3)
  );
  backdrop-filter: blur(1.5rem);
  position: relative;

  @media screen and (max-width: 900px) {
    min-height: 350px;
  }

  @media screen and (orientation: landscape) and (max-device-width: 900px) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 768px) {
    min-width: 85%;
  }

  @media screen and (max-width: 480px) {
    min-width: 92%;
  }

  .result {
    margin-bottom: 1rem;
    color: #eee;

    p {
      margin-bottom: 0.7rem;
    }

    // ul {
    //   list-style: none;

    //   li {
    //     font-size: 1.5rem;

    //     @media screen and (max-width: 1200px) {
    //       font-size: 1.35rem;
    //     }

    //     @media screen and (max-width: 1024px) {
    //       font-size: 1.2rem;
    //     }
    //   }
    // }
  }

  h2 {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2rem;
    letter-spacing: 1px;
    position: relative;

    @media screen and (max-width: 1200px) {
      margin-bottom: 1.5rem;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 120px;
      background-color: #ccc;
      border-radius: 2px;
    }
  }

  .input-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px) {
      margin-bottom: 1.25rem;
    }

    @media screen and (orientation: portrait) and (max-width: 480px) {
      margin-bottom: 1.7rem;
    }

    label {
      font-size: 1.1rem;
      margin-bottom: 0.75rem;
    }

    select {
      cursor: pointer;
      outline: none;
      border: none;
      color: #fff;
      background: #234964c2;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding: 0.5rem;
      font-size: 1rem;
      border-bottom: 1px solid #ccc;

      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;

      background-image: linear-gradient(45deg, transparent 50%, #aaa 50%),
        linear-gradient(135deg, #aaa 50%, transparent 50%),
        linear-gradient(to right, #aaa, #aaa);
      background-position: calc(100% - 20px) calc(1em - 2px),
        calc(100% - 15px) calc(1em - 2px), calc(100% - 2.25em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 1em;
      background-repeat: no-repeat;

      &::-webkit-scrollbar {
        width: 0.7rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      &:hover::-webkit-scrollbar {
        width: 0.7rem;
      }

      &:hover::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: rgba($color: #fff, $alpha: 0.7);
        border-radius: 15px;

        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.8);
        }
      }
    }

    &.btns {
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      &.contact {
        width: 50%;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }
    }

    &.contact-info {
      margin-bottom: 1rem;

      input {
        color: #fff;
        width: 100%;
        font-family: inherit;
        font-size: 1rem;
        padding: 0.2rem 0.5rem;
        border: 0;
        border-bottom: 2px solid #aaa;
        display: block;
        background-color: transparent;

        &:focus {
          outline: none;
          border-bottom: 2px solid #0fd4ad;

          &:invalid {
            border-bottom: 2px solid #f8422e;
          }

          &.error {
            border-bottom: 2px solid #f8422e;
          }
        }

        &::-webkit-input-placeholder {
          color: #ccc;
        }
      }

      label {
        color: #ccc;
        font-size: 1rem;
        margin-left: 0.5rem;
        margin-bottom: -0.75rem;
        display: block;
        transform: translateY(-250%);
        transition: all 0.2s;
      }

      input:placeholder-shown + label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-100%);
      }
    }

    &.recaptcha {
      opacity: 0.85;

      @media screen and (max-width: 600px) {
        transform: scale(0.8) translateX(-30px);
      }
    }
  }

  .loader-container {
    position: absolute;
    bottom: -5rem;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 1024px) {
      bottom: -4rem;
    }
  }

  .error-msg {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.85rem;
    color: #f8422e;
    text-align: center;
  }
}

.PhoneInput {
  margin-left: 7px;
}
