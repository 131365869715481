.steps {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    opacity: 0.3;
    font-size: 1.25rem;
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #ccc;
    border-radius: 7px;
    position: relative;
    transform: rotate(45deg);
    transition: opacity 0.25s;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
      width: 3rem;
      height: 3rem;
      border-radius: 5px;
    }

    @media screen and (max-width: 480px) {
      font-size: 0.8rem;
      width: 2.3rem;
      height: 2.3rem;
      border-radius: 3px;
    }

    &.active {
      opacity: 1;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .step-line {
    opacity: 0.3;
    margin: 0 3rem;
    height: 1px;
    width: 3.5rem;
    background: #ccc;
    transition: opacity 0.25s;

    @media screen and (max-width: 768px) {
      width: 2rem;
      margin: 0 1.5rem;
    }

    @media screen and (max-width: 480px) {
      width: 1.7rem;
      margin: 0 1rem;
    }

    @media screen and (max-width: 350px) {
      width: 1.3rem;
      margin: 0 0.7rem;
    }

    &.active {
      opacity: 1;
    }
  }
}
