.carousel {
  display: flex;
  padding: 1.5rem 0;

  // @media screen and (max-width: 480px) {
  // }

  &__country {
    height: 520px;
    width: 292px;
    position: relative;
    transition: transform 0.4s;

    @media screen and (max-width: 1400px) {
      height: 470px;
      width: 263px;
    }

    @media screen and (max-width: 1200px) {
      height: 420px;
      width: 236px;
    }

    @media screen and (max-width: 1024px) {
      height: 370px;
      width: 208px;
    }

    @media screen and (orientation: landscape) and (max-device-width: 900px) {
      height: 270px;
      width: 152px;
    }

    @media screen and (max-width: 600px) {
      height: 320px;
      width: 180px;
    }

    @media screen and (max-width: 480px) {
      height: 270px;
      width: 152px;
    }

    &:last-child {
      margin-right: 15px;
    }

    &:not(:last-child) {
      margin-right: 25px;

      @media screen and (max-width: 1400px) {
        margin-right: 23px;
      }

      @media screen and (max-width: 1200px) {
        margin-right: 22px;
      }

      @media screen and (max-width: 1024px) {
        margin-right: 21px;
      }

      @media screen and (max-width: 900px) {
        margin-right: 20px;
      }

      @media screen and (orientation: landscape) and (max-device-width: 900px) {
        margin-right: 15px;
      }

      @media screen and (max-width: 600px) {
        margin-right: 18px;
      }

      @media screen and (max-width: 480px) {
        margin-right: 15px;
      }
    }

    picture {
      height: 520px;
      width: 292px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 7px;
      }

      @media screen and (max-width: 1400px) {
        height: 470px;
        width: 263px;
      }

      @media screen and (max-width: 1200) {
        height: 420px;
        width: 236px;
      }

      @media screen and (max-width: 1024px) {
        height: 370px;
        width: 208px;
      }

      @media screen and (orientation: landscape) and (max-device-width: 900px) {
        height: 270px;
        width: 152px;
      }

      @media screen and (max-width: 600px) {
        height: 320px;
        width: 180px;
      }

      @media screen and (max-width: 480px) {
        height: 270px;
        width: 152px;
      }
    }

    &--glass {
      color: #fff;
      position: absolute;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      border-radius: 7px;
      top: 0;
      left: 0;
      width: 100%;
      height: 520px;
      background: linear-gradient(
        to right bottom,
        rgba(70, 70, 70, 0.7),
        rgba(70, 70, 70, 0.3)
      );
      backdrop-filter: blur(1.5rem);
      transition: opacity 0.4s;

      @media screen and (max-width: 1400px) {
        height: 470px;
        width: 263px;
      }

      @media screen and (max-width: 1200px) {
        height: 420px;
        width: 236px;
      }

      @media screen and (max-width: 1024px) {
        height: 370px;
        width: 208px;
      }

      @media screen and (orientation: landscape) and (max-device-width: 900px) {
        height: 270px;
        width: 152px;
      }

      @media screen and (max-width: 600px) {
        height: 320px;
        width: 180px;
      }

      @media screen and (max-width: 480px) {
        height: 270px;
        width: 152px;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
      }

      svg {
        width: 2.5rem;
        height: auto;
      }

      h2 {
        color: #fff;
        margin: 0 0 1rem 0;
        transition: transform 0.25s;

        @media screen and (orientation: landscape) and (max-device-width: 900px) {
          font-size: 1.25rem;
        }

        @media screen and (max-width: 480px) {
          font-size: 1.25rem;
        }

        &:hover {
          transform: scale(1.05);
        }

        &:active {
          transform: scale(1);
        }
      }

      p {
        font-size: 0.85rem;
        text-align: left;
        opacity: 0.9;

        @media screen and (orientation: landscape) and (max-device-width: 900px) {
          display: none;
        }

        @media screen and (max-width: 480px) {
          display: none;
        }
      }
    }

    &:hover .carousel__country--glass {
      opacity: 1;
    }
  }
}
