.navbar__mobile {
  z-index: 9;
  background-color: #2e4866;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  transition: all 0.3s;

  @media screen and (max-width: 480px) {
    position: fixed;
  }

  @media screen and (orientation: landscape) and (max-width: 800px) {
    width: 35%;
  }

  &.close {
    transform: translateX(100%);
  }

  &--menu {
    flex: 1;
    max-height: 50%;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (orientation: landscape) and (max-width: 800px) {
      max-height: 90%;
    }

    a {
      color: #ccc;
      font-size: 1.3rem;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        color: #fff;
        transform: scale(1.05);
      }

      &:active {
        color: #fff;
        transform: scale(1);
      }
    }
  }
}
