.btn {
  cursor: pointer;
  min-width: 180px;
  outline: none;
  background: transparent;
  border: 1px solid #ddd;
  color: #ddd;
  padding: 0.7rem 3rem;
  font-size: 1.2rem;
  letter-spacing: 1px;
  border-radius: 0.4rem;
  transition: all 0.25s;

  @media screen and (orientation: landscape) and (max-device-width: 900px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 600px) {
    padding: 0.7rem 1.7rem;
    min-width: 130px;
  }

  @media screen and (max-width: 480px) {
    padding: 0.6rem 1.5rem;
    min-width: 110px;
  }

  span {
    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  &:hover {
    transform: translateY(-5px);
    background: #ddd;
    color: #1c2938;
    box-shadow: 5px 5px 10px rgba($color: #000, $alpha: 0.4);
  }

  &:active {
    transform: translateY(-2px);
    background: #ddd;
    color: #1c2938;
    box-shadow: 3px 3px 7px rgba($color: #000, $alpha: 0.3);
  }

  &.cta {
    border: 1px solid #8bd8bd;
    color: #8bd8bd;
    font-weight: bold;

    &:hover {
      background: #8bd8bd;
      color: #2c3e50;
    }

    &:active {
      background: #8bd8bd;
      color: #fff;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      transform: translateY(0);
      background: transparent;
      color: #ddd;
      box-shadow: none;
    }
  }
}
